((global) => {
    /**
     * The old way to make sure zones doesn't watch an event is to use
     * the private variable: __zone_symbol__BLACK_LISTED_EVENTS
     *
     * The new way is to use the private variable: __zone_symbol__UNPATCHED_EVENTS
     * dist/client/polyfills.js: Zone.__load_patch('util', (global, Zone, api)
     */
    const unpatchedEvents = global.__zone_symbol__UNPATCHED_EVENTS || [];

    /**
     * Events emitted from wdpr-media-player triggering change detection
     */
    // Video events
    unpatchedEvents.push('timeupdate');
    unpatchedEvents.push('durationchange');
    // When the mouse moves over the player it causes change detection
    unpatchedEvents.push('mousemove');

    /**
     * Events emitted from DisneyID
     */
    // Events sent from a webWorker to the main page
    unpatchedEvents.push('message');

    /**
     * Events emitted from analytics
     */
    // Events from framework-bottom.js
    unpatchedEvents.push('scroll');

    // Set the events to ignore
    global.__zone_symbol__UNPATCHED_EVENTS = unpatchedEvents;
})(window as any);
